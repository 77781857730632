import {useEffect, useState} from 'react';
import {HeaderPage, SubHeaderPage} from "@/ui/sochi/header-page.tsx";

interface RatingMember {
  id: number;
  name: string;
  rating: number;
}

export const RatingTable = () => {
  const [rating, setRating] = useState<RatingMember[]>([]);
  const [me, setMe] = useState<RatingMember | null>(null);


  useEffect(() => {
    fetch('/api/game/ratings')
      .then((response) => response.json())
      .then((data) => {
        console.log('RATINGG', data);
        setRating(data);
      });

    fetch('/api/game/member')
      .then((response) => response.json())
      .then((data) => {
        console.log('ME', data);
        setMe(data);
      });
  }, []);

  const meIndex = rating.findIndex((member) => member.name === me?.name);

  return (
    <div className="w-full flex flex-col items-center max-w-[400px]">
      <HeaderPage>Рейтинг</HeaderPage>


      <div className="mt-4 w-full max-h-[700px] overflow-x-scroll scrollbar-hide">

        <a className="text-[26px] text-black my-4" href='/'>←</a>

        <SubHeaderPage>Рейтинг лучших игроков</SubHeaderPage>
        <div className="flex flex-col items-center w-full gap-2">


          <div
            className="rounded-[8px] gradient-main text-white font-medium leading-[24px] py-3 px-2 w-full flex flex-row items-center justify-between"
          >
            <div className="flex flex-row w-full items-center my-1 justify-between">
                  <span
                    className="text-white w-full flex flex-row items-center justify-between text-[16px] ml-4 leading-[24px]"><span
                    className="mr-4"><span className="mr-2">{meIndex}</span> <span>(Вы)</span> </span><div>{me?.name}</div>  <span>{me?.rating}</span></span>
            </div>
          </div>


          {rating.map((member, index) => {
            if (member.rating === 0) return null;

            if (member.name === me?.name) {
              return (
                <div
                  className="rounded-[8px] gradient-main text-white font-medium leading-[24px] py-3 px-2 w-full flex flex-row items-center justify-between"
                  key={member.name}>
                  <div className="flex flex-row w-full items-center justify-between">
                  <span
                    className="text-white w-full flex flex-row items-center justify-between text-[16px] ml-4 leading-[24px]"><span
                    className="mr-4">{index + 1}</span> <div>{member.name}</div>  <span>{member.rating}</span></span>
                  </div>
                </div>
              )
            }

            return (
              <div
                className="rounded-[8px] bg-gray-200  font-medium leading-[24px]  py-3 px-2 w-full flex flex-row items-center justify-between"
                key={member.name}>
                <div className="flex flex-row w-full items-center justify-between">
                  <span
                    className="text-[#7758C4] w-full flex flex-row items-center justify-between text-[16px] ml-4 leading-[24px]"><span
                    className="mr-4">{index + 1}</span> <div>{member.name}</div>  <span>{member.rating}</span></span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
