import './styles.css';
import React from 'react';
import {clsx} from 'clsx';
import {AboutIcon, HelpIcon, RatingIcon, RocketIcon, RulesIcon} from "@/ui/sochi/icons.tsx";


export const ArrowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 10.4L8.65 8L6.25 5.6L7.05 4.75L10.25 8L7.05 11.25L6.25 10.4Z"
        fill="#7B939B"
      />
    </svg>
  );
};

export interface MenuItemProps {
  title: string;
  href: string;
  icon: React.ReactNode;
  isFirstChild?: boolean;
  onClick?: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
                                                    title,
                                                    icon,
                                                    href,
                                                    onClick,
                                                    isFirstChild,
                                                  }) => {
  return (
    <a
      onClick={onClick}
      className={clsx(
        isFirstChild ? 'gradient-main' : '',
        'rounded-[8px] font-medium leading-[24px]  py-3 px-2 w-full flex flex-row items-center justify-between',
      )}
      href={href}
    >
      <div className="flex flex-row items-center justify-between">
        {icon}

        <span
          className={clsx(
            isFirstChild ? 'text-white' : 'text-[#7758C4]',
            'text-[16px] ml-4 leading-[24px]',
          )}
        >
          {title}
        </span>
      </div>
      <ArrowIcon/>
    </a>
  );
};

export const Menu = () => {
  return (
    <div className="w-full mt-[100px] gap-5 flex max-w-[400px] flex-col items-center">
      <MenuItem
        title={'Старт'}
        href={'/game'}
        icon={<RocketIcon/>}
        isFirstChild
      />
      <MenuItem title={'Рейтинг'} href={'/game/rating'} icon={<RatingIcon/>}/>
      <MenuItem title={'О игре'} href={'/about'} icon={<AboutIcon/>}/>
      <MenuItem title={'Правила'} href={'/rules'} icon={<RulesIcon/>}/>
      <MenuItem title={'Помощь'} href={'/help'} icon={<HelpIcon/>}/>
    </div>
  );
};
