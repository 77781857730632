import {
  answer,
  gameStore, Question,
  setAttempt,
  setGameOver,
  setTestStore,
} from '@/game/game-store.tsx';
import {useEffect, useState} from 'react';
import {clsx} from 'clsx';
import {useUnit} from 'effector-react';


type answerWithIdx = answer & { idx: number };

export const QuestionModal: React.FC<{ isOpen: boolean }> = ({isOpen}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean>();

  const $gameStore = useUnit(gameStore);

  const [answer, setAnswer] = useState<answerWithIdx | null>(null);

  const [passedQuestions, setPassedQuestions] = useState<number[]>([]);

  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);

  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const questions = $gameStore?.questions || [];

  const getRandomQuestion = (): Question => {
    const remainingQuestions = questions.filter(
      (question) => !passedQuestions.includes(question.id),
    );


    if (remainingQuestions.length === 0) {
      return questions[Math.floor(Math.random() * questions.length)];
    }

    const randomIndex = Math.floor(Math.random() * remainingQuestions.length);
    return remainingQuestions[randomIndex];
  };

  useEffect(() => {
    if (isOpen) {
      setActiveQuestion(getRandomQuestion());
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      let timer = setTimeout(() => {
        setIsSubmitted(false);
        setTestStore(false);
        setActiveQuestion(getRandomQuestion());
      }, 5000);

      return () => {
        setActiveQuestion(getRandomQuestion());
        clearTimeout(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && isOpen) {
      void fetch('/api/game/questions', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          question: activeQuestion?.id,
          answer: answer?.idx,
        }),
      });

      let timer = setTimeout(() => {
        setTestStore(false);
        setActiveQuestion(getRandomQuestion());
        setIsSubmitted(false);
      }, 1000);
      return () => {
        setActiveQuestion(getRandomQuestion());
        clearTimeout(timer);
      };
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (!isCorrectAnswer && activeQuestion && isSubmitted) {
      const timer = setTimeout(() => {
        if ($gameStore!.attempts === 0) {
          setGameOver(true);
        } else {
          setAttempt($gameStore!.attempts - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const handleAnswer = (answer: answer, index: number) => {
    setIsCorrectAnswer(answer.is_correct);
    setPassedQuestions([...passedQuestions, activeQuestion!.id]);
    setAnswer({...answer, idx: index});
  };

  return (
    isOpen &&
    !isLoading && (
      <div
        className="left-0 z-[999] top-0 duration-300 transition-all flex flex-col absolute w-screen h-screen bg-black/70 pt-6 px-4">
        <div className="bg-white flex flex-col rounded-[12px] p-4 mt-[64px]">
          <div className="text-wrap text-[15px] my-1">
            {activeQuestion?.title}
          </div>
          <div className="mt-4 flex flex-col gap-2">
            {activeQuestion?.answers.map((item, index) => (
              <div
                onClick={() => handleAnswer(item, index)}
                className={clsx(
                  'transition-all bg-gray-200 duration-300',
                  !isSubmitted &&
                  (answer?.title === item.title
                    ? 'bg-purple-600 text-white'
                    : 'bg-[#F0F0F0] text-black'),
                  isSubmitted &&
                  (item.is_correct
                    ? 'bg-green-500 text-white'
                    : 'bg-red-500 text-white'),
                  'rounded-[16px] px-3 py-2.5 text-[16px] font-medium cursor-pointer',
                )}
                key={item.title + item.is_correct}
              >
                {item.title}
              </div>
            ))}
          </div>
          <button
            className="mt-4 rounded-[8px] bg-[#F0F0F0] text-[#7758C4] py-2.5 flex flex-row items-center w-full justify-center"
            onClick={() => setIsSubmitted(true)}
          >
            Отправить
          </button>
        </div>
      </div>
    )
  );
};
