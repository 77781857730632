
export const EndGame = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img width={300} height={300} src="/game-assets/sochi/game-over.png" alt="end-game" className="w-full"/>
      <div className="">
        <h2 className="text-purple-800 mt-[100px] mb-[37px] z-50 text-[24px] leading-[28px]">
          Подводим итоги
        </h2>
      </div>
    </div>
  )
}