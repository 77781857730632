import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {HeaderPage, SubHeaderPage} from "@/ui/sochi/header-page.tsx";

export const Help = () => {
  const [text, setText] = useState<string | undefined>();
  const navigate = useNavigate();
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!isMessageSent && text && text.length > 0) {
      fetch('api/support', {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        redirect: 'follow',
        body: JSON.stringify({
          "message": text
        })
      }).then(() => {
        setIsMessageSent(true);
      })
    } else {
      navigate('/')
    }
  }
  const handleTyping = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }

  return (
    <div className="pt-100px">
      <HeaderPage>Обращение в тех. поддержку</HeaderPage>
      <div className="text-white text-xs mt-2">Оставь сообщение в поле ниже, чтобы сотрудники технической поддержки
        связались с тобой в Телеграм для решения проблемы.
      </div>

      <div className="flex gap-y-4 w-full flex-col px-4 py-6 bg-white rounded-xl mt-8">

        <h2
          className="text-[18px] font-medium text-black leading-6"> {isMessageSent ? "Ваше сообщение отправлено" : "С чем возникли проблемы?"}</h2>

        {!isMessageSent && (<textarea onChange={handleTyping}
                                      className="border border-soft-gray-40 rounded-[8px] p-3 outline-none resize-none h-[110px]"
                                      placeholder="Ваше обращение..."></textarea>)
        }

        {
          isMessageSent && (
            <p>Скоро наши сотрудники свяжутся с тобой в Телеграм для решения проблемы</p>
          )
        }


        <button disabled={!text && !isMessageSent} onClick={handleSubmit}
                className="bg-[#7758C4] disabled:bg-[#7758C4]/70 w-full text-white font-medium rounded-[22px] py-2.5">
          {isMessageSent ? "В главное меню" : "Отправить"}
        </button>
      </div>
    </div>
  )
}
