import {HeaderPage, SubHeaderPage, TextPage} from "@/ui/sochi/header-page.tsx";

export function RulesPage() {
  return (
    <div className="w-full">
      <HeaderPage>Правила</HeaderPage>
      <div className="flex flex-col items-center">
        <svg
          width="203"
          height="173"
          viewBox="0 0 203 173"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M91.4353 170.717C91.4353 171.978 70.967 173 45.7186 173C20.4702 173 0.00195312 171.978 0.00195312 170.717C0.00195312 169.456 20.4702 168.433 45.7186 168.433C70.967 168.433 91.4353 169.455 91.4353 170.717Z"
              fill="#0E152E"
            />
          </g>
          <path
            d="M60.5245 93.7829L60.5228 93.7841C60.0443 94.1091 59.3927 93.9864 59.0663 93.5091L57.5586 91.3026L59.2925 90.1235L60.8002 92.33C61.126 92.8073 61.0024 93.4574 60.5245 93.7829Z"
            fill="#E1B58E"
          />
          <path
            d="M55.8721 65.4627C55.8721 65.4627 56.7715 65.7616 57.8726 66.3156C58.9736 66.8702 60.904 68.0488 60.875 68.7301C60.8454 69.4114 58.6011 68.7653 58.6011 68.7653C58.6011 68.7653 60.2433 70.8916 60.0485 71.6269C59.8275 72.4605 58.7857 71.6366 58.7857 71.6366C58.7857 71.6366 59.0785 72.6662 58.6262 73.1196C58.1739 73.5731 57.4408 72.4111 57.4408 72.4111C57.4408 72.4111 57.647 73.5464 57.2096 73.935C56.7727 74.3237 55.8049 72.9253 55.8049 72.9253C55.8049 72.9253 56.1541 74.3879 55.3486 74.3749C54.7346 74.3652 52.2227 68.5056 52.2227 68.5056L55.8721 65.4627Z"
            fill="#DA988B"
          />
          <path
            d="M55.1249 75.1523L52.6035 76.8667L53.008 77.4588L55.5294 75.7444L55.1249 75.1523Z"
            fill="#BB8569"
          />
          <path
            d="M59.8792 70.7383C59.3677 69.416 58.3247 69.2751 58.3583 70.7383C58.3583 70.7383 56.9115 69.6507 57.1137 71.4628C57.1137 71.4628 55.3981 70.1201 55.6669 72.3691C55.6669 72.3691 53.8037 71.7577 54.6684 73.5738L56.8192 72.039L59.8792 70.7383Z"
            fill="#DA988B"
          />
          <path
            d="M57.8711 66.316C58.9721 66.8706 60.9025 68.0491 60.8735 68.7304C60.8439 69.4117 58.5996 68.7657 58.5996 68.7657L57.8711 66.316Z"
            fill="#DA988B"
          />
          <path
            d="M58.2773 64.5598L48.4652 48.9647L43.3376 41.0237C43.3376 41.0237 38.57 43.2796 37.7361 48.0351C37.134 51.4695 46.0227 63.3046 51.0097 69.617C53.3826 67.8601 55.816 66.1906 58.2773 64.5598Z"
            fill="#3C2A69"
          />
          <path
            d="M25.2325 63.7912C25.2689 57.252 28.8256 45.4789 28.8256 45.4789C28.8256 45.4789 28.6114 32.9164 32.5679 27.1938C34.7244 24.0742 45.0821 23.652 45.0821 23.652L50.6546 25.9795C50.6546 25.9795 54.2238 27.5035 56.4412 31.7243C58.2686 35.203 53.3745 51.622 53.3745 51.622L53.4463 62.1326C53.4542 63.323 52.54 64.3186 51.3501 64.414L27.6829 66.0312C26.3711 66.1301 25.2251 65.1039 25.2325 63.7912Z"
            fill="#7758C4"
          />
          <path
            d="M71.4546 33.8809L71.1094 32.041C71.1094 32.041 72.06 29.0476 72.5305 28.5163C73.001 27.985 74.5321 25.3541 74.7184 25.674C74.8392 25.882 74.5304 27.3838 74.2998 28.4094C74.4809 28.2316 74.6774 28.0418 74.8961 27.8361C75.2071 27.5435 78.6726 24.5125 79.1101 24.9955C79.5008 25.2274 78.7404 26.4792 78.7404 26.4792C78.7404 26.4792 79.8483 26.7815 79.1608 28.5833C79.1095 28.718 79.0486 28.8345 78.9813 28.9351C78.8281 29.2726 78.6179 29.5135 78.6179 29.5135C78.6179 29.5135 79.1095 29.5408 79.3704 29.8363C79.6313 30.1323 78.8902 30.9818 78.8902 30.9818L74.1215 35.5913L71.4546 33.8809Z"
            fill="#DA988B"
          />
          <path
            d="M53.5159 47.9807L70.3518 30.8826L75.7146 35.9257C75.7146 35.9257 60.0977 58.2077 55.272 58.5799C50.4469 58.9526 47.0742 54.904 47.0742 54.904L53.5159 47.9807Z"
            fill="#7758C4"
          />
          <path
            d="M43.0488 14.0311L43.9636 17.3359V14.1288L43.0488 14.0311Z"
            fill="#0E152E"
          />
          <path
            d="M49.1855 23.0697L52.3258 24.9699L53.232 28.8475L50.912 26.1012L50.2906 28.181L49.1855 23.0697Z"
            fill="#DDCCC4"
          />
          <path
            d="M43.6966 23.4002L43.8345 14.4387C43.8345 14.4387 40.2026 14.9427 40.4778 12.1169C40.7233 9.59905 43.4562 9.93601 44.5243 11.3498C44.5243 11.3498 43.9017 2.10295 51.5042 3.03826C58.7217 3.9264 56.0554 14.054 55.5991 16.5349C54.796 20.9046 52.3894 22.3859 50.0791 22.1615L49.7305 24.8685C49.7305 24.8685 49.4707 26.288 47.2498 25.947C44.2902 25.4925 43.6966 23.4002 43.6966 23.4002Z"
            fill="#DA988B"
          />
          <path
            d="M43.4974 13.1577C43.5099 13.1616 43.523 13.1645 43.5366 13.1662C43.6397 13.1775 43.7326 13.1025 43.744 12.9997C43.7987 12.4985 43.5965 11.9803 43.2183 11.6462C42.8395 11.312 42.2989 11.1768 41.8073 11.2922C41.7059 11.316 41.6433 11.4172 41.6672 11.5183C41.6917 11.6195 41.7931 11.6808 41.8939 11.6581C42.2693 11.5695 42.6805 11.6723 42.9693 11.9269C43.2581 12.182 43.4113 12.5769 43.3703 12.9593C43.3601 13.0486 43.4148 13.1304 43.4974 13.1577Z"
            fill="#0E152E"
          />
          <path
            d="M50.0751 22.1625C47.378 21.7546 45.9102 20.1919 45.9102 20.1919C45.9102 20.1919 46.6113 22.0694 49.976 22.9325L50.0751 22.1625Z"
            fill="#0E152E"
          />
          <path
            d="M78.751 171.678L78.3574 170.518L91.4328 166.292L91.5911 167.457L78.751 171.678Z"
            fill="white"
          />
          <path
            d="M78.3594 170.518L75.2402 162.887L82.2942 160.477L83.9022 164.835C83.9022 164.835 90.6657 164.354 91.4347 166.292L78.3594 170.518Z"
            fill="#0E152E"
          />
          <path
            d="M51.3517 64.4133L86.3159 162.096L75.3458 167.364L41.6621 87.8454L48.4951 64.6082L51.3517 64.4133Z"
            fill="#3C2A69"
          />
          <path
            d="M0 163.513L0.682979 162.496L12.0022 170.273L11.1717 171.108L0 163.513Z"
            fill="white"
          />
          <path
            d="M0.683594 162.496L4.85483 155.383L11.0647 159.499L8.57719 163.424C8.57719 163.424 13.0765 168.485 12.0028 170.273L0.683594 162.496Z"
            fill="#0E152E"
          />
          <path
            d="M27.6862 66.0311C27.6862 66.0311 24.3279 73.0283 26.7686 82.5814L24.5546 118.266L2.55859 157.512L11.964 162.675C11.964 162.675 37.4675 123.757 38.4564 120.902C39.4452 118.047 47.8754 71.3037 47.8754 71.3037L48.4969 64.6089L27.6862 66.0311Z"
            fill="#3C2A69"
          />
          <path
            d="M42.547 10.2665C42.547 10.2665 41.9307 4.04831 42.363 3.46247C46.8134 -2.56589 53.1024 1.10942 53.1024 1.10942C53.3656 0.838378 53.6526 0.628132 54.048 0.37527C54.76 -0.0810194 55.9396 0.00251106 56.7206 0.326971C57.5829 0.685524 58.0597 1.30262 58.2403 2.12713C57.7521 1.96745 57.2281 2.00666 56.761 2.21861C56.2933 2.43056 55.9106 2.82264 55.7106 3.29427C56.8043 3.14028 58.0403 3.73181 58.6191 4.66996C59.1978 5.60811 59.2621 7.67022 58.6316 8.57427C57.4422 7.08266 55.3398 6.39908 53.4966 6.90197C53.8526 7.43781 53.7461 8.20151 53.2414 8.60154C53.0762 7.79409 52.4719 6.9997 51.704 6.69513C50.607 7.90603 48.9483 8.6328 47.3152 8.52995C47.2075 10.0505 46.6459 10.8694 45.902 12.2007C45.9185 12.986 45.8963 13.8463 45.9128 14.631C45.5608 14.3736 45.1917 14.1196 44.8403 13.8622L44.5196 11.3512C44.5196 11.3512 43.762 10.4477 42.547 10.2665Z"
            fill="#0E152E"
          />
          <path
            d="M43.1699 3.10341C43.1699 3.10341 41.898 1.90445 40.5936 3.23865C39.2892 4.57229 41.9567 10.2876 41.9567 10.2876C41.9567 10.2876 43.228 10.0319 44.5199 11.3496L43.1699 3.10341Z"
            fill="#0E152E"
          />
          <path
            d="M52.2214 58.7486C52.214 58.7486 52.2071 58.748 52.1997 58.748C52.0927 58.74 49.5528 58.5144 46.3248 55.0994C43.1567 51.748 32.7659 37.0376 32.325 36.4131C32.235 36.2847 32.2658 36.1074 32.3939 36.0176C32.5232 35.9273 32.7004 35.9574 32.791 36.0864C32.898 36.2387 43.5993 51.3877 46.7389 54.7096C49.7989 57.9473 52.2191 58.1798 52.2431 58.182C52.3991 58.1945 52.517 58.3315 52.5039 58.4877C52.4919 58.6355 52.3684 58.7486 52.2214 58.7486Z"
            fill="#281C48"
          />
          <path
            d="M53.5145 46.7723C53.4284 46.7723 53.343 46.7331 53.2872 46.6592C53.151 46.4785 39.6417 28.5929 37.5398 27.0746C37.4122 26.9825 37.3838 26.8047 37.476 26.678C37.5683 26.5507 37.7477 26.5228 37.8736 26.6149C40.0444 28.1826 53.1835 45.5785 53.7418 46.3177C53.8363 46.4433 53.8112 46.6212 53.6853 46.7155C53.6346 46.7541 53.5748 46.7723 53.5145 46.7723Z"
            fill="#281C48"
          />
          <path
            d="M42.7038 21.7598L49.6872 25.0743L50.2875 28.1814L49.0492 26.0744L47.01 28.7314L41.2285 24.01L42.7038 21.7598Z"
            fill="white"
          />
          <path
            d="M36.8022 65.408C36.4109 67.4451 35.3548 71.5716 33.1254 72.2716L29.46 117.925C29.4565 117.966 29.4446 118.007 29.4241 118.043L5.93064 159.364L5.43164 159.09L28.8978 117.817L33.0855 65.662L33.6597 65.6227L33.1761 71.6449C34.9111 70.8544 35.831 67.3513 36.2109 65.4483L36.8022 65.408ZM27.8582 72.5836C27.9807 72.5722 28.0821 72.4847 28.1094 72.3654L29.6359 65.8972L29.0419 65.9381L27.6025 72.0369L26.0907 72.1739C26.0674 72.3625 26.0451 72.5546 26.0252 72.7501L27.8582 72.5836Z"
            fill="#281C48"
          />
          <path
            d="M163.516 0.814237C163.516 0.814237 160.204 -1.13907 156.602 1.00508C153 3.14922 151 10.6425 151.047 16.6396C151.095 22.6361 154.837 26.6144 163.126 26.6144C171.414 26.6144 176.659 24.7491 176.571 16.0222C176.483 7.29468 172.292 1.86323 170.195 0.720063C168.1 -0.424353 163.516 0.814237 163.516 0.814237Z"
            fill="#0E152E"
          />
          <g opacity="0.2">
            <path
              d="M197.383 171.095C197.383 172.147 178.424 172.999 155.037 172.999C131.65 172.999 112.691 172.147 112.691 171.095C112.691 170.044 131.65 169.192 155.037 169.192C178.424 169.193 197.383 170.044 197.383 171.095Z"
              fill="#0E152E"
            />
          </g>
          <path
            d="M166.551 25.0824L168.294 15.4014C168.294 15.4014 172.089 16.7092 172.39 13.6077C172.657 10.8443 169.651 10.6316 168.205 11.9294C168.205 11.9294 170.81 2.28017 162.46 1.50808C154.111 0.73599 155.271 12.1752 155.237 14.943C155.178 19.8187 157.428 22.1606 159.956 22.4051L159.76 25.3943C159.76 25.3943 159.558 28.0105 162.016 28.1103C165.291 28.2438 166.551 25.0824 166.551 25.0824Z"
            fill="#DA988B"
          />
          <path
            d="M168.933 14.0913C168.918 14.0932 168.904 14.0932 168.888 14.092C168.775 14.0826 168.691 13.9822 168.701 13.8693C168.747 13.318 169.074 12.8022 169.551 12.5222C170.029 12.2422 170.637 12.2104 171.142 12.438C171.245 12.4854 171.291 12.6076 171.244 12.7112C171.197 12.8147 171.075 12.8596 170.971 12.8141C170.587 12.6394 170.123 12.6638 169.759 12.8777C169.395 13.0916 169.147 13.4845 169.111 13.9049C169.103 14.0028 169.027 14.0789 168.933 14.0913Z"
            fill="#0E152E"
          />
          <path
            d="M159.962 22.4065C162.945 22.5349 164.852 21.161 164.852 21.161C164.852 21.161 163.703 23.0351 159.906 23.2571L159.962 22.4065Z"
            fill="#0E152E"
          />
          <path
            d="M159.562 25.7439C159.562 25.7439 155.398 26.955 154.394 28.1918C153.389 29.4285 153.079 33.3544 153.079 33.3544L159.047 27.4858L159.562 25.7439Z"
            fill="#7758C4"
          />
          <path
            d="M143.214 42.1393C143.214 42.1393 140.721 39.8112 140.451 39.5149C140.183 39.2187 138.772 36.8718 138.267 36.5507C137.762 36.2295 133.569 35.9501 133.223 36.1802C132.878 36.4103 132.815 37.7824 132.815 37.7824L142.084 45.2738L143.214 42.1393Z"
            fill="#DA988B"
          />
          <path
            d="M134.807 37.587L139.165 34.8223C139.409 34.7294 139.684 34.8229 139.82 35.045C140.002 35.3418 139.861 35.731 139.531 35.8426L135.889 38.0342C135.525 37.8938 135.165 37.7435 134.807 37.587Z"
            fill="#311C17"
          />
          <path
            d="M148.643 42.9751L143.669 40.4356L141.449 45.5115L149.836 49.478L148.643 42.9751Z"
            fill="#7758C4"
          />
          <path
            d="M169.982 25.3653C169.982 25.3653 176.674 28.6969 178.158 33.0625C179.641 37.4281 169.647 63.3381 164.497 64.3303C159.348 65.3226 139.157 51.1979 139.157 51.1979L143.123 44.8778L149.329 47.7235C149.329 47.7235 146.891 42.0357 148.593 37.8884C150.295 33.7411 159.558 25.7445 159.558 25.7445L160.246 27.9123L162.755 25.4414L169.982 25.3653Z"
            fill="#7758C4"
          />
          <path
            d="M149.136 57.2716L146.109 69.8583C145.895 70.7501 146.358 71.6657 147.203 72.0205L149.262 72.8862L151.37 58.3886L149.136 57.2716Z"
            fill="#7758C4"
          />
          <path
            d="M166.526 87.1125L174.039 119.726C174.039 119.726 182.672 130.87 183.435 134.947C184.199 139.023 187.44 138.468 189.263 140.034C191.085 141.599 189.588 144.161 191.831 146.723C194.074 149.285 195.927 147.061 197.632 149.382C199.338 151.704 202.041 156.817 202.041 156.817L188.667 166.875C188.667 166.875 161.97 126.765 160.094 124.149C158.217 121.532 154.353 109.007 154.353 109.007L166.526 87.1125Z"
            fill="#3C2A69"
          />
          <path
            d="M129.967 161.238L127.589 171.097L125.383 170.554V167.33C125.383 167.33 122.838 169.339 122.33 169.634C121.822 169.929 111.623 167.216 111.623 167.216C111.623 167.216 113.642 165.495 114.996 165.06C116.35 164.624 120.468 164.568 120.468 164.568L124.179 159.997L129.967 161.238Z"
            fill="#0E152E"
          />
          <path
            d="M149.152 66.2069C149.152 66.2069 140.386 105.143 138.631 109.85C136.877 114.557 135.356 114.478 135.678 118.387C135.999 122.296 132.49 122.425 131.533 124.595C130.576 126.764 131.891 129.243 130.655 133.386C129.42 137.529 114.474 158.583 114.474 158.583L129.832 166.576L166.527 87.1114C166.527 87.1114 171.947 83.473 172.898 76.052C173.85 68.6305 169.129 62.6508 169.129 62.6508L149.152 66.2069Z"
            fill="#553E8E"
          />
          <path
            d="M124.953 164.039L164.1 65.7809C164.164 65.6206 164.086 65.4392 163.926 65.3756C163.764 65.3132 163.584 65.3899 163.521 65.5495L124.397 163.749L124.953 164.039ZM172.606 77.6467L170.344 76.9276C170.205 76.8833 170.116 76.7486 170.128 76.6033L171.069 65.9213C171.245 66.2905 171.419 66.6877 171.59 67.1075L170.771 76.4087L172.73 77.0318C172.692 77.2394 172.653 77.4452 172.606 77.6467Z"
            fill="#281C48"
          />
          <path
            d="M159.561 25.743C159.561 25.743 151.825 34.9881 150.555 38.0702C149.286 41.1524 151.066 51.0293 151.066 51.0293L149.154 66.2054L156.087 66.4848C156.087 66.4848 152.96 40.6503 153.258 38.7238C153.556 36.7974 163.812 25.8933 163.812 25.8933L162.933 24.9135L160.303 27.3034L159.561 25.743Z"
            fill="white"
          />
          <path
            d="M172.414 51.455L175.574 71.1689L157.831 72.8796C156.788 72.98 155.866 72.2036 155.787 71.1577L154.79 57.9174L172.414 51.455Z"
            fill="#7758C4"
          />
          <path
            d="M169.985 25.3652L169.444 22.5245L162.084 24.1304L160.304 26.7098L159.874 23.8061L159.043 23.8766L157.37 26.9886L159.432 26.6132L160.249 27.9116L162.758 25.4407L163.342 27.7607L169.985 25.3652Z"
            fill="white"
          />
          <path
            d="M138.704 43.1056L127.271 35.8356L127.695 33.8474L138.535 41.118L138.704 43.1056Z"
            fill="#1E355D"
          />
          <path
            d="M142.624 47.9995L142.627 45.9452C142.627 45.9452 141.005 42.9042 140.393 42.4246C139.78 41.9451 137.606 39.4111 137.47 39.794C137.381 40.0429 138.014 41.6014 138.467 42.6629C138.237 42.507 137.987 42.3417 137.711 42.1633C137.318 41.9095 132.982 39.33 132.608 39.9387C132.234 40.2668 133.302 41.4661 133.302 41.4661C133.302 41.4661 132.171 42.0137 133.27 43.8204C133.353 43.9557 133.441 44.0686 133.534 44.164C133.765 44.4977 134.04 44.716 134.04 44.716C134.04 44.716 133.516 44.8432 133.295 45.2143C133.073 45.5854 134.04 46.355 134.04 46.355L140.095 50.3776L142.624 47.9995Z"
            fill="#DA988B"
          />
          <path
            d="M164.498 64.33C159.348 65.3223 139.158 51.1976 139.158 51.1976L143.124 44.8775L157.193 51.7359L164.498 64.33Z"
            fill="#7758C4"
          />
          <path
            d="M159.362 5.79142C163.577 3.43274 165.139 4.22167 164.85 8.48127C164.561 12.7409 166.494 14.1697 168.208 11.9301C169.923 9.69055 170.659 8.37587 170.659 8.37587L169.029 3.85371L166.392 1.57174L160.881 0.8564L155.203 3.84124L155.73 6.91837C155.73 6.91837 156.168 4.86279 157.331 4.22478C158.224 3.73459 159.362 5.79142 159.362 5.79142Z"
            fill="#0E152E"
          />
          <path
            d="M198.342 156.553L202.434 165.831L200.351 166.739L198.394 164.176C198.394 164.176 197.59 167.318 197.366 167.861C197.141 168.404 187.389 172.439 187.389 172.439C187.389 172.439 187.949 169.846 188.762 168.677C189.573 167.509 192.812 164.965 192.812 164.965L192.988 159.079L198.342 156.553Z"
            fill="#0E152E"
          />
        </svg>
        <SubHeaderPage>Итак начнем с правил игры</SubHeaderPage>
        <TextPage>
          Отвечай на вопросы чтобы хитрый гусь тебя не покусал, пробеги как
          можно больше и стань лучшим в списке игроков!
        </TextPage>
      </div>
    </div>
  );
}
