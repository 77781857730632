interface HeaderPageProps {
  children?: React.ReactNode;
}

export const HeaderPage: React.FC<HeaderPageProps> = ({children}) => {
  return (
    <h1 className="text-[#7758C4] mb-[52px] font-semibold text-[30px] leading-[36px]">
      {children}
    </h1>
  );
};

export const SubHeaderPage: React.FC<HeaderPageProps> = ({ children }) => {
  return (
    <h2 className="text-[#7758C4] font-semibold text-[15px] leading-[36px]">
      {children}
    </h2>
  );
};


export const TextPage: React.FC<HeaderPageProps> = ({ children }) => {
  return (
    <p className="text-[#7758C4] mt-4 font-normal text-[15px] leading-[24px]">
      {children}
    </p>
  );
}