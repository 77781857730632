'use client';


import {MenuIcon, RedoIcon} from "@/ui/sochi/icons.tsx";
import {useNavigate} from "react-router-dom";

export const ActionBar = () => {
  const navigate = useNavigate();

  if(window.location.pathname === '/'){
    return null;
  }


  return (
    <div className="flex w-full h-14 flex-row max-w-[400px] items-center justify-between absolute bottom-2 left-0">
      <button onClick={() => navigate(-1)} className="cursor-pointer">
        <RedoIcon/>
      </button>
      <a href={'/'}>
        <MenuIcon/>
      </a>
    </div>
  );
};
